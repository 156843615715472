<template>
	<div class="container">
		<h1>Правила за полазване на petvetmanager.com</h1>

		<p class="lead">
			To read the english version of the Terms and Conditions please
			<router-link to="/termsandconditions">click here</router-link>
		</p>

		<h2>Въведение</h2>

		<p>
			Тези стандартни Правила За Ползване написани на тази страница ще
			напътстват ползването на нашият уебсайт , petvetmanager достъпен на
			https://www.petvetmanager.com.
		</p>

		<p>
			Ползвайки този уебсайт, Вие се съгласявате да приемете всички правиал за
			ползване посочени по-долу. Вие не трябва да ползвате този уебсайт, ако не
			сте съгласни с някое от правилата за ползване.
		</p>

		<p>
			На малолетни и лица под 18 години не се разрешава ползването на този
			уебсайт.
		</p>

		<h2>Права върху интелектуалната собственост</h2>

		<p>
			Освен вашето собствено съдържание, petvetmanager.com и/или неговите
			легални партньори притежават цялата интелектуална собственост, права и
			материали съсдържащи се на този уебсайт.
		</p>

		<p>
			Дава Ви се ограничен лиценз само за разлгеждане на материалите съдържащи
			се на този уебсайт .
		</p>

		<h2>Ограничения</h2>

		<p>Забранява се следното:</p>

		<ul>
			<li>
				публикуването и споделянето на каквито и да било материали от този
				уебсайт в други медии;
			</li>
			<li>
				продажба и каквато и да било монетизация на материали от този уебсайт;
			</li>
			<li>
				ползването на този уебсайт по какъвто и да било начин, който би навредил
				на същият;
			</li>
			<li>
				ползването на този уебсайт по какъвто и да било начин, който би попречил
				на други потребители да ползват съшщият;
			</li>
			<li>
				ползването на този уебсайт в противоречие с действащи закони и норми или
				зловредни към потребилите и на самият уебсайт, както и на други бизнеси;
			</li>
			<li>
				използването на уебсайта за събиране, источване или подобни на лични
				данни;
			</li>
			<li>използването на уебсайта за рекламни или маркетингови цели.</li>
		</ul>

		<p>
			Определени зони от уебсайта са с ограничен достъп и petvetmanager.com може
			да добавя нови такива без изрично известие по всяко време. Всяко
			потребителско име и парола ползвани за този уебсайт са поверителни и не се
			разкриват пред трети лица и Вие също не трябва да ги разкривате.
		</p>

		<h2>Вашето съдържание</h2>

		<p>
			В стандартните Правила за Ползване на този уебсайт, "Вашето съдържание" ще
			значи всеки аудио,видео или текстови документ, който решите да покажете
			чрез този уебсайт. Споделяйки Вашето Съдържание, Вие давате на
			petvetmanager.com споделени права в световен мащаб да използва, публикува,
			превежда и предоставя на трети лица и медии.
		</p>

		<p>
			Вашето Съдържание трябва да бъде Ваше личнои не трябва да нарушава правата
			на трети лица. petvetmanager.com запазва правото си да премахва Вашето
			Съдържание по всяко време без предизвестие.
		</p>

		<h2>Вашата поверителност</h2>

		<p>
			Моля прочетете
			<router-link to="/privacypolicybg">{{ $t('privacy') }}</router-link
			>.
		</p>

		<h2>Няма гаранции</h2>

		<p>
			Този уебсайт е предоставен "както е," със всичките си недостатъци, и
			petvetmanager.com не издава никакви гаранции свързани със този уебсайт или
			материалите съдържащи се на този уебсайт. Също така, нищо съдържащо се на
			този уебсайт не се счита като легален съвет към потребителя.
		</p>

		<h2>Ограничена отговорност</h2>

		<p>
			petvetmanager.com и неговите създатели не носят отговорност за злоупотреби
			на трети лица или предоставяне на каквато и да било информация доброволно
			от потребителите под формата на свободен текст в съответните полета на
			сайта.
		</p>

		<!-- <h2>Indemnification</h2>

    <p>
      You hereby indemnify to the fullest extent petvetmanager.com from and
      against any and/or all liabilities, costs, demands, causes of action,
      damages and expenses arising in any way related to your breach of any of
      the provisions of these Terms.
    </p>

    <h2>Severability</h2>

    <p>
      If any provision of these Terms is found to be invalid under any
      applicable law, such provisions shall be deleted without affecting the
      remaining provisions herein.
    </p> -->

		<h2>Промяна на правилата за ползване</h2>

		<p>
			petvetmanager.com запазава правото си да променя и актуализира правилата
			за ползване по всяко време. Ваше задължение е редовно да проверявате
			съдържанието на правилата за ползване. petvetmanager.com винаги ще
			правивсичко възможно да известява потребителите си за настъпили промени.
		</p>

		<!-- <h2>Assignment</h2>

    <p>
      The petvetmanager.com is allowed to assign, transfer, and subcontract its
      rights and/or obligations under these Terms without any notification.
      However, you are not allowed to assign, transfer, or subcontract any of
      your rights and/or obligations under these Terms.
    </p>

    <h2>Entire Agreement</h2>

    <p>
      These Terms constitute the entire agreement between petvetmanager.com and
      you in relation to your use of this Website, and supersede all prior
      agreements and understandings.
    </p>

    <h2>Governing Law & Jurisdiction</h2>

    <p>
      These Terms will be governed by and interpreted in accordance with the
      laws of the State of bg, and you submit to the non-exclusive jurisdiction
      of the state and federal courts located in bg for the resolution of any
      disputes.
    </p> -->

		<br />

		<v-btn color="grey" dark @click="goBack">
			{{ $t('back') }}
		</v-btn>
	</div>
</template>

<script>
export default {
	methods: {
		goBack() {
			this.$router.go(-1);
		},
	},
};
</script>

<style lang="scss" scoped>
.container {
	h2 {
		font-size: 24px;
	}

	p {
		font-size: 16px;
	}

	a {
		margin: 0.5rem 0;
	}

	.lead {
		border: 1px solid #f0ae1d;
		border-radius: 5px;
		padding: 1rem;

		a {
			color: #f0ae1d;
		}
	}
}
</style>
